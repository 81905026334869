import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import websocket from "./websocket";

const defaultState = {
  initializing: true,

  //search
  searchResultSortId: "type"

};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, defaultState, composeEnhancers(
  applyMiddleware(thunk, websocket)
));

if (module.hot) {
  module.hot.accept("./reducers", () => {
    const updatedReducer = require("./reducers").default;
    store.replaceReducer(updatedReducer);
  });
}

export default store;
