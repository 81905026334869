import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../actions";
import "./Hours.css";

class Hours extends React.PureComponent {
  render() {
    return (
      <div className="hours-container">
        <div className="hours-content">
          By appointment; please call for current availabity.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hours));