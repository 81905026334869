// Redux middleware that intercepts (some) actions and forwards them to the back-end over websocket.
// Messages from the back-end over websocket are dispatched as actions.
const websocket = ({ dispatch, getState }) => {

  //  // Initialize the web socket.
  //  const socket = io(window.location.protocol + '//' + window.location.host, { path: `${SMPROBER_API_ENDPOINT}/ws` });

  //  socket.on('connect', () => {
  //    // TODO - action when socket is connected.
  //  });

  //  // Websocket callbacks when real-time updates are received.
  //  socket.on('instances', update => dispatch(actions.updateInstances(update)));
  //  socket.on('statistics', update => dispatch(actions.updateStatistics(update)));

  return next => action => {

    // Invoke the rest of chain and reducer.
    next(action);

    // Get the updated state.
    const state = getState();

    // TODO - intercept some actions and emit websocket messages.

    return state;
  };

};

export default websocket;
