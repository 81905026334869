import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../actions";
import aetna from "../assets/aetna.png";
import bcbs from "../assets/bcbs.png";
import bcn from "../assets/bcn.png";
import medicare from "../assets/medicare.jpg";
import "./Insurance.css";

class Insurance extends React.PureComponent {
  render() {
    return (
      <div className="insurance-container">
        <div className="insurance-plan">
          Below are the insurances I am currently accepting along with self-pay.
        </div>
        <div className="insurance-carriers">
          <img key="aetna" src={aetna} alt="Aetna" width="350px" style={{paddingTop: "40px", paddingBottom: "20px", marginLeft: "25px"}} />
          <img key="bcbs" src={bcbs} width="300px" height="167px" alt="BCBS" />
          <img key="bcn" src={bcn} alt="BCN" style={{paddingBottom: "20px"}} />
          <img key="medicare" src={medicare} width="300px" alt="Medicare" style={{paddingBottom: "40px"}}/>
          <svg width="350" height="60" viewBox="0 0 2656 453" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_0_1)">
            <path d="M418.69 120.892C372.563 17.1561 235.328 -25.8808 132.862 18.8509C78.414 40.7378 27.1407 89.0315 6.33232 151.106C5.92398 152.309 6.43441 152.481 7.09273 151.376C73.3697 40.7624 228.765 40.05 294.665 135.777C349.868 214.359 314.69 315.982 224.548 350.102C301.309 383.166 390.198 339.073 420.523 264.225C440.685 220.083 439.889 164.444 418.69 120.892Z" fill="#62C43A"/>
            <path d="M16.4438 333.669C62.5714 437.387 199.805 480.428 302.272 435.698C356.72 413.816 407.992 365.516 428.801 303.463C429.209 302.257 428.7 302.064 428.041 303.174C361.765 413.792 206.369 414.515 140.468 318.783C85.264 240.181 120.443 138.586 210.585 104.448C133.825 71.3954 44.936 115.498 14.6105 190.313C-5.5525 234.463 -4.75719 290.122 16.4438 333.669Z" fill="#009845"/>
            <path d="M636.02 226.639C660.191 226.639 674.317 212.208 674.317 193.364V192.744C674.317 171.085 659.246 159.468 635.078 159.468H597.726V226.639H636.02ZM549.383 115.84H639.16C691.582 115.84 723.286 146.921 723.286 191.813V192.433C723.286 243.289 683.732 269.647 634.451 269.647H597.726V335.577H549.383V115.84Z" fill="#009845"/>
            <path d="M740.228 167.298H787.939V201.207C797.671 177.972 813.365 162.915 841.619 164.164V214.073H839.107C807.403 214.073 787.939 233.231 787.939 273.409V335.572H740.228V167.298Z" fill="#009845"/>
            <path d="M861.693 167.331H909.407V335.57H861.693V167.331ZM860.44 106.43H910.663V148.8H860.44V106.43Z" fill="#009845"/>
            <path d="M1059.45 252.395V251.759C1059.45 226.954 1041.56 205.305 1015.19 205.305C987.876 205.305 971.555 226.318 971.555 251.123V251.759C971.555 276.564 989.447 298.213 1015.81 298.213C1043.13 298.213 1059.45 277.177 1059.45 252.395ZM924.468 252.395V251.759C924.468 203.421 963.391 164.175 1015.81 164.175C1067.92 164.175 1106.53 202.784 1106.53 251.123V251.759C1106.53 300.098 1067.61 339.343 1015.19 339.343C963.08 339.343 924.468 300.734 924.468 252.395Z" fill="#009845"/>
            <path d="M1121.59 167.298H1169.3V201.207C1179.03 177.972 1194.73 162.915 1222.98 164.164V214.073H1220.47C1188.76 214.073 1169.3 233.231 1169.3 273.409V335.572H1121.59V167.298Z" fill="#009845"/>
            <path d="M1238.34 167.331H1286.06V335.57H1238.34V167.331ZM1237.09 106.43H1287.31V148.8H1237.09V106.43Z" fill="#009845"/>
            <path d="M1326.23 287.855V208.145H1306.14V167.325H1326.23V124.316H1373.94V167.325H1413.49V208.145H1373.94V280.025C1373.94 290.997 1378.65 296.352 1389.33 296.352C1398.12 296.352 1405.96 294.139 1412.86 290.378V328.675C1402.82 334.625 1391.21 338.409 1375.2 338.409C1346.01 338.409 1326.23 326.794 1326.23 287.855Z" fill="#009845"/>
            <path d="M1549.09 167.326H1598.68L1534.02 339.668C1521.14 373.891 1507.33 386.743 1478.77 386.743C1461.52 386.743 1448.64 382.365 1436.4 375.139L1452.41 340.609C1458.69 344.375 1466.53 347.2 1472.81 347.2C1480.97 347.2 1485.37 344.681 1489.45 336.208L1423.53 167.326H1474.06L1512.37 281.907L1549.09 167.326Z" fill="#009845"/>
            <path d="M1711.04 115.84H1759.39V202.786H1848.53V115.84H1896.88V335.577H1848.53V247.368H1759.39V335.577H1711.04V115.84Z" fill="#009845"/>
            <path d="M2032.78 238.261C2029.95 216.919 2017.4 202.478 1997.31 202.478C1977.53 202.478 1964.67 216.589 1960.89 238.261H2032.78ZM1914.12 252.395V251.759C1914.12 203.727 1948.35 164.175 1997.31 164.175C2053.49 164.175 2079.24 207.802 2079.24 255.528C2079.24 259.297 2078.92 263.679 2078.61 268.084H1961.52C1966.23 289.733 1981.29 301.04 2002.64 301.04C2018.66 301.04 2030.27 296.022 2043.45 283.773L2070.76 307.942C2055.07 327.4 2032.47 339.343 2002.01 339.343C1951.48 339.343 1914.12 303.867 1914.12 252.395Z" fill="#009845"/>
            <path d="M2200.39 276.257V267.78C2192.23 264.012 2181.56 261.492 2169.93 261.492C2149.53 261.492 2136.97 269.64 2136.97 284.712V285.347C2136.97 298.229 2147.65 305.764 2163.03 305.764C2185.32 305.764 2200.39 293.519 2200.39 276.257ZM2090.84 287.231V286.595C2090.84 249.882 2118.77 232.927 2158.64 232.927C2175.59 232.927 2187.83 235.753 2199.76 239.827V237.001C2199.76 217.22 2187.52 206.246 2163.65 206.246C2145.45 206.246 2132.59 209.708 2117.2 215.336L2105.28 178.929C2123.8 170.758 2142 165.435 2170.57 165.435C2196.63 165.435 2215.45 172.335 2227.39 184.275C2239.95 196.826 2245.58 215.336 2245.58 237.943V335.577H2199.45V317.374C2187.83 330.232 2171.83 338.709 2148.6 338.709C2116.89 338.709 2090.84 320.506 2090.84 287.231Z" fill="#009845"/>
            <path d="M2273.2 106.43H2320.92V335.57H2273.2V106.43Z" fill="#009845"/>
            <path d="M2357.94 287.855V208.145H2337.85V167.325H2357.94V124.316H2405.67V167.325H2445.21V208.145H2405.67V280.025C2405.67 290.997 2410.36 296.352 2421.05 296.352C2429.83 296.352 2437.68 294.139 2444.59 290.378V328.675C2434.52 334.625 2422.93 338.409 2406.91 338.409C2377.72 338.409 2357.94 326.794 2357.94 287.855Z" fill="#009845"/>
            <path d="M2464.66 106.43H2512.38V191.195C2523.36 177.063 2537.49 164.175 2561.66 164.175C2597.75 164.175 2618.79 188.038 2618.79 226.655V335.57H2571.09V241.719C2571.09 219.123 2560.4 207.502 2542.2 207.502C2524 207.502 2512.38 219.123 2512.38 241.719V335.57H2464.66V106.43Z" fill="#009845"/>
            <path d="M2617.45 95.6659H2609.99V115.839H2608.17V95.6659H2600.73V94.1068H2617.45V95.6659Z" fill="#009845"/>
            <path d="M2623.4 94.1068L2631.41 113.296L2639.44 94.1068H2641.92V115.839H2640.07V106.388L2640.21 96.6734L2632.13 115.839H2630.71L2622.65 96.7453L2622.8 106.316V115.839H2620.95V94.1068H2623.4Z" fill="#009845"/>
            </g>
            <defs>
            <clipPath id="clip0_0_1">
            <rect width="5409.75" height="4003.93" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insurance));