import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../actions";
import "./Contact.css";

class Contact extends React.PureComponent {
  render() {
    return (
      <div className="contact-container">
        <div className="contact-content">
          Phone: 248-790-0410<br />
          Fax: 248-457-5488<br />
          Email: nancy@nancyloseyphd.com<br />
          <br />
          <span style={{fontSize: "smaller"}}>(email is for brief, administrative exchanges only, please do not send private, clinical information via email)</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));