import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "../actions";
import {BrowserView, MobileView} from "react-device-detect";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {Dimmer} from 'semantic-ui-react';
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Hours from "./Hours";
import Insurance from "./Insurance";
import Contact from "./Contact";
import "./App.css";

export const ROUTES = [
  {path: "/", exact: true, name: "Home", component: Home},
  {path: "/hours", name: "Hours", component: Hours},
  {path: "/insurance", name: "Insurance", component: Insurance},
  {path: "/contact", name: "Contact", component: Contact}
];

class App extends React.Component {
  componentDidMount() {
    this.props.initialize();
  }

  render() {
    return [
      <BrowserView key="browser">
        <Router basename="/">
          <Dimmer.Dimmable className="app">
            <div className="app-header">
              <Header routes={ROUTES} />
            </div>
            <div className="app-body">
              <Switch>
                {ROUTES.map(({path, exact, component}) => <Route key={path} path={path} exact={!!exact} component={component} />)}
                <Redirect to="/" />
              </Switch>
            </div>
            <div className="app-footer">
              <Footer />
            </div>
          </Dimmer.Dimmable>
        </Router>
      </BrowserView>,
      <MobileView key="mobile">
        <Router basename="/">
          <Dimmer.Dimmable className="app-mobile">
            <div className="app-header">
              <Header routes={ROUTES} />
            </div>
            <div className="app-body">
              <Switch>
                {ROUTES.map(({path, exact, component}) => <Route key={path} path={path} exact={!!exact} component={component} />)}
                <Redirect to="/" />
              </Switch>
            </div>
            <div className="app-footer">
              <Footer />
            </div>
          </Dimmer.Dimmable>
        </Router>
      </MobileView>
    ];
  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);