import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import classNames from "classnames";
import * as actions from "../actions";
import {isMobile} from "react-device-detect";
import office from "../assets/office.jpg";
import safety from "../assets/IS Office Safety Precautions.pdf"
import privacy from "../assets/IS HIPAA Notice of Privacy Policies.pdf"
import "./Home.css";

class Home extends React.PureComponent {
  render() {

    const homePlan = (
      <div key="plan" className="home-plan">
        Are you feeling stressed, anxious, worried, upset or burned out? &nbsp;Many are, as these are trying times. &nbsp;I work with people who are dealing with
        a variety of difficult emotions and circumstances.<br /><br />
        I also have experience in women’s issues and helping people who cope with chronic pain.<br /><br />
        My goal is to help you change the things that you can change and to find solutions.<br /><br />
        I am here to help you develop skills to live a healthier life.<br /><br />
        You do not have to walk this road alone.<br /><br />
        I offer an atmosphere of compassion, acceptance, and encouragement. &nbsp;Using the tools of psychology, together we can seek ways to help you heal
        and grow.<br /><br />
        To learn about the next step, please call.<br /><br />
      </div>
    )

    const homeSafety = (
      <div key="safety" className="home-safety">
        To see a list of Office Safety Precautions being taken during the pandemic, please <a href={safety} target="_blank" rel="noopener noreferrer">click here</a>.
      </div>
    )

    const homePrivacy = (
      <div key="safety" className="home-privacy">
        To see a Notice of Privacy Policies, please <a href={privacy} target="_blank" rel="noopener noreferrer">click here</a>.
      </div>
    )

    const homeTitleClasses = classNames({
      "home-title": !isMobile,
      "home-title-mobile": isMobile
    });
    const homeTitle = (
      <div key="title" className={homeTitleClasses}>
        Inspiring Solutions Psychological Services, PLLC<br />
        Nancy Losey, PhD<br />
        Licensed Psychologist<br />
        <br />
        200 East Big Beaver Road<br />
        Troy, MI 48083<br />
        (248) 790-0410<br />
      </div>
    )

    if (!isMobile) {
      return (
        <div className="home-container">
          {homePlan}
          <div className="home-site">
            {homeTitle}
            <div className="home-building">
              <img key="building" src={office} height="200px" width="300px" alt="Building" />
            </div>
          </div>
          <div className="home-map">
            <div className="home-google">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2938.6799205219445!2d-83.14489734883212!3d42.56208897907171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c46aa4d12dab%3A0x67dfc073e8f245f0!2s200%20E%20Big%20Beaver%20Rd%2C%20Troy%2C%20MI%2048083!5e0!3m2!1sen!2sus!4v1645648462717!5m2!1sen!2sus"
                width="600"
                height="450"
                frameborder="0"
                style={{border: "0"}}
                allowfullscreen=""
                title="Map"
              >
              </iframe>
            </div>
          </div>
          {homePrivacy}
          {homeSafety}
        </div>
      );
    }
    else {
      return (
        <div className="home-container">
          <div className="header-telehealth-content">Telehealth Services Available!</div>
          {homePlan}
          {homeTitle}
          <div className="location-building-mobile">
            <img key="building" src={office} height="200px" width="300px" alt="Building" />
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2938.6799205219445!2d-83.14489734883212!3d42.56208897907171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c46aa4d12dab%3A0x67dfc073e8f245f0!2s200%20E%20Big%20Beaver%20Rd%2C%20Troy%2C%20MI%2048083!5e0!3m2!1sen!2sus!4v1645648462717!5m2!1sen!2sus"
            width="300"
            height="300"
            frameborder="0"
            style={{border: "0"}}
            allowfullscreen=""
            title="Map"
          >
          </iframe>
          {homePrivacy}
          {homeSafety}
        </div>
      );
    }
  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));