import * as actionTypes from "./actionTypes";

export function setInitializing(initializing) {
  return {
    type: actionTypes.SET_INITIALIZING,
    initializing
  };
}

export function initialize() {
  return async dispatch => {
    dispatch(setInitializing(true));
    try {
      //TODO initialize something
    }
    catch(error) {
      console.log(error);
    }
    finally {
      dispatch(setInitializing(false));
    }
  };
}