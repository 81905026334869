import React from "react";
import classNames from "classnames";
import * as actions from "../actions";
import {isMobile} from "react-device-detect";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter, matchPath} from "react-router";
import {Link} from "react-router-dom";
import {ROUTES} from "./App";
import nancy from "../assets/nancy.jpg";
import telehealth from "../assets/telehealth.png";
import {Dropdown, Menu} from "semantic-ui-react";
import "./Header.css";

class Header extends React.Component {
  render() {
    const {location, routes} = this.props;
    const {pathname, state={}} = location;
    let name = state.name;
    if (!name) { // then find it
      const found = ROUTES.find(r => r.path === pathname);
      if (found) {
        name = found.name;
      }
    }

    //classes between mobile and browser
    const imageClasses = classNames({
      "nancy-image": !isMobile,
      "nancy-image-mobile": isMobile
    });
    const companyClasses = classNames({
      "company-name": !isMobile,
      "company-name-mobile": isMobile
    });
    const phoneNumberClasses = classNames({
      "phone-number": !isMobile,
      "phone-number-mobile": isMobile
    });
    const size = !isMobile ? "200px" : "50px";

    const resultant = []
    if (!isMobile) {
      resultant.push(
        <div key="nancy">
          <img key="nancy" className={imageClasses} src={nancy} height={size} width={size} alt="Nancy Losey PhD" />
        </div>
      );
      resultant.push(
        <div key="center" className="header-center-container">
          <div className="header-telehealth">
            <img key="telehealth" src={telehealth} height={79} width={100} alt="telehealth" />
            <div className="header-telehealth-content">Telehealth<br />Available</div>
          </div>
          <div className="header-company-container">
            <div className={companyClasses}>Nancy Losey, PhD</div>
            <div className={phoneNumberClasses}>(248) 790-0410</div>
          </div>
          <div className="header-menu-container">
            <Menu secondary>
              {routes.map(({path, exact, name}) => (
                <Menu.Item key={path} as={Link} to={{pathname: path, state: {name}}} active={!!matchPath(pathname, {path, exact})}>
                  <span className="header-menu-item">{name}</span>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      );
    }
    else { // mobile
      resultant.push(
        <Menu size="large" secondary style={{height: "50px"}}>
          <Menu.Item header style={{padding: "0"}}>
          <img key="nancy" className={imageClasses} src={nancy} height={size} width={size} alt="Nancy Losey PhD" />
          </Menu.Item>
          <Dropdown text={name} className="link item" style={{minWidth: "120px"}}>
            <Dropdown.Menu>
              {routes.map(({path, exact, name}) => (
                <Dropdown.Item key={path} as={Link} to={{pathname: path, state: {name}}} active={!!matchPath(pathname, {path, exact})}>
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item header>
            <div className="header-company-container">
              <div className={companyClasses}>Nancy Losey, PhD</div>
              <div className={phoneNumberClasses}>(248) 790-0410</div>
            </div>
          </Menu.Item>
        </Menu>
      );
    }

    //finished
    return resultant;

  }
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));