import React from "react";
import moment from 'moment';
import * as actions from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import "./Footer.css";

class Footer extends React.Component {
  render() {
    const now = moment();
    return (
      <div className="footer-container">
        Copyright &copy; {now.format("YYYY")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Inspiring Solutions Psychological Services, LLC &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Rights Reserved
      </div>
    )
  };
}

const mapStateToProps = state => { return {...state}; };
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));