import * as actionTypes from './actionTypes';

function setInitializing(state, action) {
  return {
    ...state,
    initializing: action.initializing
  };
}

export default function reducers(state = {}, action) {
  switch (action.type) {
    case actionTypes.SET_INITIALIZING:                                        return setInitializing(state, action);
    default:                                                                  return state;
  }
}